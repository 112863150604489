.navbar {
  background-color:  transparent;
    padding : 0px 20px;
    height : 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
}
.bg-user {
    height : 50px;
    width : 50px;
    border-radius: 50%;
    border: 1px solid #eaeaea;
    background-image: url('../../../../images/img1.png');
}
.dropdown-toggle {
    padding: 0px;
    display: flex;
    align-items: center;
    color: black;
    background: none;
    border:none;
}
.dropdown-toggle:hover,.dropdown-toggle:active {
    padding: 0px;
    display: flex;
    align-items: center;
    color: black;
    background: none;
}
.search-container {
    display: flex;
    align-items: center;
  }
  
  #search-input {
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 15px;
    margin-right: 8px;
  }
  
  #search-button {
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  #search-button:hover {
    background-color: #0056b3;
  }
  