.heading-uservideo{
  color: black;
  background-color: #a3c4bc;
  border-radius: 30px; 
  box-shadow: 3px 3px 10px rgb(188, 188, 188); 
  padding:20px ; 
  width : 100%;
  margin: 50px 0px;
  height : 80vh;
  overflow: auto;
  background-image: url('../../images/back_img1.png');
  background-repeat: no-repeat;
  background-size: cover;
}
.p_20 {
  padding: 20px;
}
.selectall-video{
  background-color: #ffffff; 
  padding:5px ; 
  box-shadow : 0px 0px 10px #ccc;
  border-radius: 10px;
  width: 200px;
  height: 35px;
  text-align: center;
  color: #4F5992;
  font-weight: bold;    
  cursor: pointer; 
  display: inline-block;
  margin-right: 20px;
 
 }
 .th-video{
  color: 
  #4F5992;
 }
 /* image css */
 .img_uservideo{
  width : 100%;
  border-radius: 10px;
  height : 300px;
}

/* circle */

.circle {
  display: flex;
  width: 100px;
  height: 100px;
  background-color: rgb(121, 31, 194);
  border-radius: 50%;
  margin-right: 20px;
}
.text {
  margin: auto;
}

/* user profile page */
.userinfo{
  font-weight: bold;
}

/* background img */
.img{
  width: "100%";
height: "400px",
}

.delete_icon:hover {
  color : red;
}