.paper {
    background-color:#f9fbf2;
    width: 354px;
    border-radius: 10px;
    padding: 20px;
    cursor: pointer;
    height: 225px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
}
.paper h1 {
    font-size: 38px;
    /* background-color:peru; */
}
.paper-list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color:rgb(205, 63, 63); */
}
.paper-list p {
    margin : 30;
}
.paper-heading {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin-top: 50px; */
    
}
.paper-heading h2 {
    color : #009821;
}
.green {
    color : #009821;
}
.blue {
    color : #0048FF;
}
.chart {
    background:#f9fbf2 ;
    margin-top: 20px;
    border-radius: 10px;
    padding: 20px;
}
.color{
    background-color:#f9fbf2;
}